export class Api {
  //public static API_ADDRESS = 'api/heroes';

  //DashBoard
  public static GET_DASH_BOARD = "DashBoard/GetDashBoard";
  public static GetListHocVien = "DashBoard/GetListHocVien";
  public static GET_NOTIFICATIONS = "DashBoard/GetNotifications";
  public static UpdateIsViewRemind = "DashBoard/UpdateIsViewRemind";
  public static DashBoardThongKeKienNghi = "DashBoard/DashBoardThongKeKienNghi";
  public static DashBoardGetList = "DashBoard/DashBoardGetList";
  public static DashBoardGetYear = "DashBoard/DashBoardGetYear";
  public static DashBoardCount = "DashBoard/DashBoardCount";

  // Captcha Controller
  public static ValidateCaptcha = "Captcha/validatorCaptcha";
  public static getImageCaptcha = "Captcha/GetCaptchaImage";

  //Account
  public static LOGIN = "Account/Login";
  public static REGISTER = "Account/Register";
  public static FORGETPASSWORD = "Account/Forgetpassword";
  public static CHANGEPASSWORD = "Account/ChangePassword";
  public static RESTORE_ACCOUNT = "Account/UnlockAccount";
  public static RESTORETOKEN = "Account/UpdateToken";
  public static RESETPASSWORD = "Account/ResetPassword";
  //Department
  public static GET_DEPARTMENT_TREE = "Department/Index";
  public static CHECK_EXISTED_CODE = "Department/CheckExistedCode";
  public static CREATEDERPARTMENT = "Department/Create";
  public static UPDATEDERPARTMENT = "Department/Update";
  public static UPDATESTATUSDERPARTMENT = "Department/UpdateStatusDepartment";
  public static RemoveUserFromDepartment =
    "Department/RemoveUserFromDepartment";
  public static DELETEDEPARTMENT = "Department/Delete";
  public static DEPARTMENTGETBYID = "Department/GetbyId";
  public static GETDEPARTMENTABYUNITID = "Department/GetDepartmentaByUnitId";
  public static GetDepartmentByUnit = "Department/GetDepartmentByUnit";
  public static GetDepartmentForProcess = "Department/GetDepartmentForProcess";
  public static GETUSERBYUNITID = "Department/GetListUser";
  public static GETUSERTOADD = "Department/GetUserToAdd";
  public static GET_UNIT_TREE = "Department/GetUnitTree";
  public static GET_TREE_ADD_USER = "Department/GetTreeAddUser";
  public static ADDDEPARTMENTUSER = "Department/AddDepartmentUser";
  public static DELETEDEPARTMENTUSER = "Department/DeleteDepartmentUser";
  public static GETUSERBYDEPID = "Department/GetUserByDepId";
  public static UPDATEREPRESENTATIVE = "Department/UpdateRepresentative";
  public static GETDROPDOWNDEPARTMENT = "Department/GetDropDown";
  public static GET_UNIT_NAME = "Department/Get_Unit_Name";
  public static GetHeadDepartment = "Department/GetHeadDepartment";
  public static GetListUserOverDepartmentId =
    "Department/GetListUserOverDepartmentId";
  public static DepartmentAddUser = "Department/DepartmentAddUser";
  public static GetDepartments = "Department/GetDepartments";

  // Laborer Controller
  public static LaborerGetDataForCreate = "Laborer/LaborerGetDataForCreate";
  public static LaborerGetList = "Laborer/LaborerGetList";
  public static LaborerGetById = "Laborer/LaborerGetById";
  public static LaborerGetByIdView = "Laborer/LaborerGetByIdView";
  public static LaborerDelete = "Laborer/LaborerDelete";
  public static LaborerCreate = "Laborer/LaborerCreate";
  public static LaborerUpdate = "Laborer/LaborerUpdate";
  public static LbTrinhDoCmktkhac_Delete = "Laborer/LbTrinhDoCmktkhac_Delete";
  public static LbTrinhDoNgoaiNgu_Delete = "Laborer/LbTrinhDoNgoaiNgu_Delete";
  public static LbTrinhDoTinHocKhac_Delete =
    "Laborer/LbTrinhDoTinHocKhac_Delete";
  public static LbTrinhDoKhac_Delete = "Laborer/LbTrinhDoKhac_Delete";
  public static LbKinhNghiem_Delete = "Laborer/LbKinhNghiem_Delete";
  public static LbNoiLamViecUuTien_Delete = "Laborer/LbNoiLamViecUuTien_Delete";

  //GroupUser
  public static GET_GROUPUSER_LIST = "GroupUser/Index";
  public static CHECK_GROUPUSER_EXISTED_CODE = "GroupUser/CheckExistedCode";
  public static CREATEGROUPUSER = "GroupUser/Create";
  public static UPDATEGROUPUSER = "GroupUser/Update";
  public static UPDATESTATUSGROUPUSER = "GroupUser/UpdateStatus";
  public static DELETEGROUPUSER = "GroupUser/Delete";
  public static GROUPUSERBYID = "GroupUser/GetbyId";
  public static ADDUSERTOGROUP = "GroupUser/AddUserToGroup";
  public static ADDLISTUSERTOGROUP = "GroupUser/AddListUserToGroup";
  public static GETCREATEGROUPUSERDATAS = "GroupUser/GetCreateGroupUserDatas";
  public static GETLISTUSERBYUNITIDANDFILTER =
    "GroupUser/GetListUserByUnitIdAndFilter";
  public static DELUSERFROMGROUPUSER = "GroupUser/DeleteUserFromGroupUser";
  public static GETLISTGROUPUSERBYUNITIDANDPAGE =
    "GroupUser/GetListGroupUserByUnitIdAndPage";

  //User
  public static GET_USER_BY_DEPARTMENT = "User/GetUserByDepartment";
  public static CREATE_USER = "User/CreateUser";
  public static GET_CREATE_USER_DATAS = "User/GetCreateUserDatas";
  public static GETUSERBYGROUPID = "User/GetListUserByGroupId";
  public static Edit_User = "User/EditUser";
  public static UPDATE_INFO = "User/UpdateInfo";
  public static UPDATE_USER_LOGIN = "User/UpdateUserLogin";
  public static LOCK_USER = "User/LockUser";
  public static DELETE_USER = "User/DeleteUser";
  public static logOut = "User/LogOut";
  public static GET_USER_BY_REGION = "User/GetUserByRegion";
  public static GET_USER_BY_UNIT = "User/GetUserByUnit";
  public static GetAllUserByUnitForForward = "User/GetAllUserByUnitForForward";
  public static GET_USER_BY_UNIT_AND_QUERY = "User/GetUserByUnitAndQuery";
  public static GetUserById = "User/GetUserById";
  public static IsNguoiDaiDien = "User/IsNguoiDaiDien";
  public static UserUpdateStatus = "User/UpdateStatus";
  public static GetListChucVuAndListPhongBan =
    "User/GetListChucVuAndListPhongBan";

  //lay danh sach co phan trang
  public static GET_USER_BY_REGION_UNIT = "User/GetUserByRegionOrUnit";

  //Catalog
  public static GETCATALOGBYUNITID = "SystemConfig/GetCatalogValueByDepartment";
  public static GET_CATALOG_VALUE_BY_ID = "SystemConfig/GetCatalogValueById";
  public static DELETE_CATALOG_VALUE = "SystemConfig/DeleteCatalogValue";
  public static UPDATE_CATALOG_VALUE = "SystemConfig/UpdateCatalogValue";
  public static CREATE_CATALOG_VALUE = "SystemConfig/CreateCatalogValue";
  public static GET_DATA_CREATE_DOCUMENT = "SystemConfig/GetDataCreateDocument";
  public static GET_DATA_UPDATE_DOCUMENT = "SystemConfig/GetDataUpdateDocument";
  public static getFileSupport = "SystemConfig/GetFileSupport";
  public static CREATE_FORM_CATALOG = "SystemConfig/CreateFormCatalog";
  public static UPDATE_FORM_CATALOG = "SystemConfig/UpdateFormCatalog";
  public static DELETE_FORM_CATALOG = "SystemConfig/DeleteFormCatalog";
  public static GET_DORMITORY_TREE = "SystemConfig/GetDormitoryTree";
  public static GET_DDL_PROFESSION = "SystemConfig/GetDropdownProfession";
  public static GET_DDL_CATALOG_VALUE = "SystemConfig/GetDropdownCatalogValue";
  public static GetUserForCreateByDepartment =
    "SystemConfig/GetUserForCreateByDepartment";
  public static GetNhomNguoiDungNguoiDung =
    "SystemConfig/GetNhomNguoiDungNguoiDung";
  public static CreateNguoiDungNhomNguoiDung =
    "SystemConfig/CreateNguoiDungNhomNguoiDung";
  public static DeleteNguoiDungNhomNguoiDung =
    "SystemConfig/DeleteNguoiDungNhomNguoiDung";
  public static GetCodeConfigByCode = "SystemConfig/GetCodeConfigByCode";

  //Folders
  public static GET_FOLDERS_TREE = "Folders/GetTreeView";
  public static CREATEFOLDERS = "Folders/CreateFolders";
  public static UPDATEFOLDERS = "Folders/UpdateFolders";
  public static FOLDERSGETBYID = "Folders/GetById";
  public static DELETEFOLDERS = "Folders/DeleteFolders";
  public static GETDATAFORCREATEFOLDERS = "Folders/GetDataForCreate";
  public static GETDOCUMENTBYFOLDERSID = "Folders/GetFoldersListDocument";
  public static GET_TREE_ADD_DOCUMENT = "Folders/GetDocumentToAdd";
  public static ADDFOLDERSDOCUMENT = "Folders/AddFoldersDocument";
  public static CREATEFILEFOLDERS = "Folders/CreateFileFolders";
  //public static ADD_OTHER_DOCUMENT = 'Folders/AddOtherDocument';
  //public static GET_OTHER_DOCUMENTID = 'Folders/GetOtherDocumentById';
  public static DELETEFILEFOLDERS = "Folders/DeleteFileFolders";
  public static GetListFileFolders = "Folders/GetListFileFolders";

  //System Config
  public static GET_SYSTEM_CONFIG = "SystemConfig/GetSystemConfigByDepartment";
  public static GET_SYSTEM_CONFIG_BY_ID = "SystemConfig/GetBySystemConfigId";
  public static UPDATE_SYSTEM_CONFIG = "SystemConfig/UpdateSystemConfig";
  public static GETDOCUMENTTYPEUNITID = "SystemConfig/GetDocumentTypeByUnitId";

  //Systemlog
  public static SYSTEMLOGGETDATAGRID = "SystemLog/Index";
  public static SYSTEMLOGDELETEDATAGRID = "SystemLog/Delete";
  public static SYSTEMLOGGETTIMELINE = "SystemLog/GetTimeLine";
  public static SYSTEMLOGGETUSERTIMELINESEARCH = "SystemLog/UserTimeLineSearch";

  public static getListSuggestUsersNew = "Workflow/GetListSuggestUsersNew";

  // FileUpload/Download
  public static DownloadApp = "UploadFiles/DownloadApp";
  public static GetEncryptedPath = "UploadFiles/GetEncryptedPath";
  public static download = "UploadFiles/DownloadFile";
  public static DownloadFilebyId = "UploadFiles/DownloadFilebyId";
  public static downloadFileSupport = "UploadFiles/DownloadFileSupport";
  public static GetFile = "UploadFiles/GetFile";
  public static uploadfiles = "UploadFiles/Uploadfiles";
  public static getFileImage = "UploadFiles/GetFile";
  public static FileGetFile = "File/Download";
  public static DownloadFileNoAuthor = "UploadFiles/DownloadFileNoAuthor";

  //History
  public static HistoryGetTimeLine = "History/TimeLine";
  public static DepartmentGroupGetList = "DepartmentGroup/GetList";
  public static DepartmentGroupDelete = "DepartmentGroup/Delete";
  public static MajorGetList = "Catalog/MajorGetList";
  public static StageGetList = "Catalog/StageGetList";
  public static RecommendationsGetList = "Catalog/RecommendationsGetList";
  public static RecommendationsTypeGetList =
    "Catalog/RecommendationsTypeGetList";
  public static ResolutionTypeGetList = "Catalog/ResolutionTypeGetList";
  public static RecomendationFieldTreeGetList =
    "Catalog/RecomendationFieldTreeGetList";
  public static GetlistcapChaId = "Catalog/GetlistcapChaId";

  public static RecommendationsFieldGetList =
    "Catalog/RecommendationsFieldGetList";
  public static ComplaintLetterGetList = "Catalog/ComplaintLetterGetList";
  public static ComplaintLetterUpdateStatus =
    "Catalog/ComplaintLetterUpdateStatus";
  public static PositionGetList = "Catalog/PositionGetList";
  public static PositionGetById = "Catalog/PositionGetList";
  public static NationGetList = "Catalog/NationGetList";
  public static NationGetById = "Catalog/NationGetById";
  public static UpdateStatus = "Catalog/UpdateStatus";
  public static DeleteCatalog = "Catalog/DeleteCatalog";
  public static KhoaHdndGetList = "Catalog/KhoaHdndGetList";
  public static CatalogCreate = "Catalog/Create";
  public static CatalogUpdate = "Catalog/Update";
  public static CatalogGetById = "Catalog/GetById";
  public static GetListYearRegion = "Catalog/GetListYearRegion";
  public static CreateYearRegion = "Catalog/CreateYearRegion";
  public static UpdateYearRegion = "Catalog/UpdateYearRegion";
  public static GetByIdYearRegion = "Catalog/GetByIdYearRegion";
  public static GetListNganhNghe = "Catalog/GetListNganhNghe";
  public static ImportExcelNganhNghe = "Catalog/ImportNganhNghe";
  public static DeleteNganhNghe = "Catalog/DeleteNganhNghe";

  //category
  public static CategoryGetList = "Catalog/CategoryGetList";

  // Letters
  public static DonThuGetList = "Letters/DonThuGetList";
  public static DonThuDelete = "Letters/DonThuDelete";
  public static DonThuGetById = "Letters/DonThuGetById";
  public static DonThuGetDataCreate = "Letters/DonThuGetDataCreate";
  public static DonThuCreate = "Letters/DonThuCreate";
  public static DonThuUpdate = "Letters/DonThuUpdate";
  public static DonThuGetData = "Letters/DonThuGetData";
  public static DonThuXuLy = "Letters/DonThuXuLy";
  public static DonThuGetGetListFilter = "Letters/GetGetListFilter";
  public static DonThuAddListTrung = "Letters/AddListTrung";
  public static DonThuGetListDonThuTrung = "Letters/GetListDonThuTrung";
  public static DONTHU_GETLIST_TRUNG_ON_PAGEVIEW =
    "Letters/GetListTrungOnPageView";

  //Meeting
  public static GetMeetingList = "Meeting/GetMeetingList";
  public static GetDropDownMeeting = "Meeting/GetDropDownMeeting";
  public static OnCreateMeeting = "Meeting/OnCreate";
  public static GetDataMeetingById = "Meeting/GetDataById";
  public static GetDataMeetingByIdForView = "Meeting/GetDataByIdForView";
  public static GetDataByIdForViewMornitoringResult =
    "Meeting/GetDataByIdForViewMornitoringResult";
  public static UpdateIsViewById = "Meeting/UpdateIsViewById";
  public static UpdateKyHopKetLuan = "Meeting/UpdateKyHopKetLuan";
  public static OnUpdateMeeting = "Meeting/OnUpdate";
  public static XoaKyHop = "Meeting/XoaKyHop";
  public static GetMeetingQuestionList = "Meeting/GetMeetingQuestionList";
  public static GetGSKetQuaKetLuanById = "Meeting/GetGSKetQuaKetLuanById";
  public static DeleteGSKetQuaKetLuanById = "Meeting/DeleteGSKetQuaKetLuanById";
  public static GetMeetingQuestionById = "Meeting/GetMeetingQuestionById";
  public static GetGiamSatKetLuanList = "Meeting/GetGiamSatKetLuanList";
  public static OnCreateMeetingQuestion = "Meeting/OnCreateMeetingQuestion";
  public static OnUpdateMeetingQuestion = "Meeting/OnUpdateMeetingQuestion";
  public static OnCreateGiamSatKetLuan = "Meeting/OnCreateGiamSatKetLuan";
  public static OnUpdateGiamSatKetLuan = "Meeting/OnUpdateGiamSatKetLuan";
  public static OnDeleteMeetingQuestionById =
    "Meeting/OnDeleteMeetingQuestionById";
  public static MeetingGetListNghiQuyet = "Meeting/MeetingGetListNghiQuyet";
  public static CreateNghiQuyet = "Meeting/CreateNghiQuyet";
  public static NghiQuyetDelete = "Meeting/NghiQuyetDelete";
  public static OnDeleteChatVanKyHop = "Meeting/OnDeleteChatVanKyHop";
  public static NghiQuyetGetById = "Meeting/NghiQuyetGetById";
  public static NghiQuyetUpdate = "Meeting/NghiQuyetUpdate";
  public static DownLoadNghiQuyet = "Meeting/NghiQuyetDownLoadFile";
  public static TaiLieuGetList = "Meeting/TaiLieuGetList";
  public static TaiLieuGetDataCreate = "Meeting/TaiLieuGetDataCreate";
  public static ThuMucById = "Meeting/GetThuMucById";
  public static TaiLieuCreate = "Meeting/TaiLieuCreate";
  public static ThuMucCreate = "Meeting/ThuMucCreate";
  public static ThuMucDelete = "Meeting/ThuMucDelete";
  public static TaiLieuDelete = "Meeting/TaiLieuDelete";
  public static TaiLieuGetById = "Meeting/TaiLieuGetById";
  public static TaiLieuUpdate = "Meeting/TaiLieuUpdate";
  public static KyHopOnProcess = "Meeting/OnProcess";
  public static BaiBoNghiQuyet = "Meeting/BaiBoNghiQuyet";
  public static GetListKyHopDropDown = "Meeting/GetListKyHopDropDown";
  public static NghiQuyetGetDataCreate = "Meeting/NghiQuyetGetDataCreate";
  public static ChatVanThongBaoChuyenVien = "Meeting/ChatVanThongBaoChuyenVien";

  // Phien hop
  public static PhienHopGetList = "Meeting/PhienHopGetList";
  public static PhienHopCreate = "Meeting/PhienHopCreate";
  public static PhienHopUpdate = "Meeting/PhienHopUpdate";
  public static PhienHopGetById = "Meeting/PhienHopGetById";
  public static PhienHopDelete = "Meeting/PhienHopDelete";
  public static PhienHopGetDataView = "Meeting/PhienHopGetDataView";
  public static PhienHopKetLuan = "Meeting/PhienHopKetLuan";
  public static PhienHopGui = "Meeting/PhienHopGui";

  // Chat van
  public static ChatVanGetList = "Meeting/ChatVanGetList";
  public static ChatVanGetById = "Meeting/ChatVanGetById";
  public static ChatVanCreate = "Meeting/ChatVanCreate";
  public static ChatVanUpdate = "Meeting/ChatVanUpdate";
  public static ChatVanDelete = "Meeting/ChatVanDelete";
  public static kHChatVanGetDataView = "Meeting/kHChatVanGetDataView";
  public static CauHoiChatVanGetListByChatVanId =
    "Meeting/CauHoiChatVanGetListByChatVanId";
  public static CauHoiChatVanCreate = "Meeting/CauHoiChatVanCreate";
  public static CauHoiChatVanUpdate = "Meeting/CauHoiChatVanUpdate";
  public static CauHoiChatVanGetById = "Meeting/CauHoiChatVanGetById";
  public static CauHoiChatVanDelete = "Meeting/CauHoiChatVanDelete";

  //Records
  public static HoSoGetList = "Records/HoSoGetList";
  public static HoSoDelete = "Records/HoSoDelete";
  public static HoSoCreate = "Records/HoSoCreate";
  public static HoSoGetById = "Records/HoSoGetById";
  public static HoSoUpdate = "Records/HoSoUpdate";
  public static HoSoGetData = "Records/HoSoGetData";
  public static HoSoGiaiQuyet = "Records/HoSoGiaiQuyet";
  public static GetListCoQuanByHoSoId = "Records/GetListCoQuanByHoSoId";
  public static CreateCoQuan = "Records/CreateCoQuan";

  // Petition
  public static PetitionGetList = "KNKienNghiCuTri/GetList";
  public static PetitionGetDataCreate = "KNKienNghiCuTri/GetDataCreate";
  public static PetitionCreate = "KNKienNghiCuTri/Create";
  public static PetitionUpdate = "KNKienNghiCuTri/Update";
  public static PetitionGetById = "KNKienNghiCuTri/GetById";
  public static PetitionDelete = "KNKienNghiCuTri/Delete";
  public static PetitionGetData = "KNKienNghiCuTri/GetKienNghiData";
  public static PetitionGetListCoQuan = "KNKienNghiCuTri/GetListCoQuan";
  public static PetitionCreateCoQuan = "KNKienNghiCuTri/CreateCoQuan";
  public static PetitionCoQuanDelete = "KNKienNghiCuTri/CoQuanDelete";
  public static PetitionCoQuanUpdate = "KNKienNghiCuTri/CoQuanUpdate";
  public static PetitionCoQuanGetById = "KNKienNghiCuTri/CoQuanGetById";
  public static PetitionGetListKienNghiTrung =
    "KNKienNghiCuTri/GetListKienNghiTrung";
  public static PetitionGetListKienNghiFilter =
    "KNKienNghiCuTri/GetListKienNghiFilter";
  public static PetitionAddListTrung = "KNKienNghiCuTri/AddListTrung";
  public static GetListKienNghiTrung = "KNKienNghiCuTri/GetListKienNghiTrung";

  // Contact
  public static ContactGetList = "Contact/ContactGetList";
  public static ContactCreate = "Contact/ContactCreate";
  public static ContactUpdate = "Contact/ContactUpdate";
  public static ContactGetById = "Contact/ContactGetById";
  public static ContactDelete = "Contact/ContactDelete";
  public static ContactDetailGetDataCreate =
    "Contact/ContactDetailGetDataCreate";
  public static ContactGetView = "Contact/ContactGetView";
  public static ContactDetailCreate = "Contact/ContactDetailCreate";
  public static ContactDetailGetById = "Contact/ContactDetailGetById";
  public static ContactDetailGetList = "Contact/ContactDetailGetList";
  public static ContactDetailUpdate = "Contact/ContactDetailUpdate";
  public static ContactDetailDelete = "Contact/ContactDetailDelete";
  public static ContactGetDataCreate = "Contact/ContactGetDataCreate";
  public static OnProcess = "Contact/OnProcess";

  // Download File
  public static DownloadFile = "File/Download";

  // Statistical
  public static StatisticalGetYear = "Statistical/StatisticalGetYear";
  public static StatisticalThongKeCuTri = "Statistical/ThongKeCuTri";
  public static StatisticalThongKeKhieuNai = "Statistical/ThongKeKhieuNai";
  public static StatisticalThongKeNghiQuyet = "Statistical/ThongKeNghiQuyet";

  // Report
  public static ReportComplain = "Report/ReportComplain";
  public static ReportKienNghi = "Report/ReportKienNghi";
  public static GetBaoCaoKienNghiById = "Report/GetBaoCaoKienNghiById";
  public static ReportResolutionGetList = "Report/ReportResolutionGetList";
  public static ReportBaoCaoVuViec = "Report/BaoCaoVuViec";
  public static DanhMucGetDropDown = "Report/DanhMucGetDropDown";
  public static ExportFileReport = "Report/ExportFileReport";
  public static ExportFileKienNghiCuTri = "Report/ExportFileKienNghiCuTri";
  public static ExportFileHoSoKhieuNai = "Report/ExportFileHoSoKhieuNai";
  public static XuatTaiLieuBaoCaoVuViec = "Report/XuatTaiLieuBaoCaoVuViec";
  public static GetDataSearchNghiQuyet = "Report/GetDataSearchNghiQuyet";

  // Publish
  public static GetListThongTinCongBoKyHop =
    "Publish/GetListThongTinCongBoKyHop";
  public static CreateCbKyHop = "Publish/CreateCbKyHop";
  public static UpdateCbKyHop = "Publish/UpdateCbKyHop";
  public static DeleteCbKyHop = "Publish/DeleteCbKyHop";
  public static GetCbKyHop = "Publish/GetCbKyHop";
  public static CbKyHopUpdateStatus = "Publish/CbKyHopUpdateStatus";
  public static GetListCbKyHop = "Publish/GetListCbKyHop";
  public static GetListCbKyHopViewMost = "Publish/GetListCbKyHopViewMost";
  public static CreateCbDongGopYKienKyHop = "Publish/CreateCbDongGopYKienKyHop";
  public static GetListCbKienNghi = "Publish/GetListCbKienNghi";
  public static GetListViewMostCbKienNghi = "Publish/GetListViewMostCbKienNghi";
  public static CbKienNghiGetList = "Publish/CbKienNghiGetList";
  public static CbKienNghiCreate = "Publish/CbKienNghiCreate";
  public static CbKienNghiUpdate = "Publish/CbKienNghiUpdate";
  public static CbKienNghiGetById = "Publish/CbKienNghiGetById";
  public static CbKienNghiDelete = "Publish/CbKienNghiDelete";
  public static CbKienNghiUpdateStatus = "Publish/CbKienNghiUpdateStatus";
  public static CbDongGopYKienKienNghiCreate =
    "Publish/CbDongGopYKienKienNghiCreate";
  public static DanhSachCongBoKyHop = "Publish/DanhSachCongBoKyHop";
  public static GetDropdownList = "Publish/GetDropdownList";
  public static UpdateTrangThaiCongBo = "Publish/UpdateTrangThaiCongBo";
  public static DanhSachCongBoKienNghi = "Publish/DanhSachCongBoKienNghi";
  public static DanhSachCongBoHoSo = "Publish/DanhSachCongBoHoSo";

  // Monitoring
  public static GiamSatGetList = "Monitoring/GiamSatGetList";
  public static GiamSatCreate = "Monitoring/GiamSatCreate";
  public static GiamSatGetDataCreate = "Monitoring/GiamSatGetDataCreate";
  public static GiamSatDelete = "Monitoring/GiamSatDelete";
  public static GiamSatGetById = "Monitoring/GiamSatGetById";
  public static GiamSatUpdate = "Monitoring/GiamSatUpdate";
  public static DoanGiamSatCreate = "Monitoring/DoanGiamSatCreate";
  public static GiamSatGetData = "Monitoring/GiamSatGetData";
  public static KetQuaGiamSatGetList = "Monitoring/KetQuaGiamSatGetList";
  public static KetQuaGiamSatCreate = "Monitoring/KetQuaGiamSatCreate";
  public static KetQuaGiamSatGetById = "Monitoring/KetQuaGiamSatGetById";
  public static KetQuaGiamSatUpdate = "Monitoring/KetQuaGiamSatUpdate";
  public static KetQuaGiamSatDelete = "Monitoring/KetQuaGiamSatDelete";
  public static ChuyenTiepKeHoach = "Monitoring/ChuyenTiepKeHoach";
  public static GetListKeHoachDropdownList =
    "Monitoring/GetListKeHoachDropdownList";
  public static GetListKeHoachDropdownListSo =
    "Monitoring/GetListKeHoachDropdownListSo";

  // Notifications
  public static GetNotification = "Notifications/GetNotification";
  public static DeleteNotification = "Notifications/DeleteNotification";
  public static NotificationGetList = "Notifications/NotificationGetList";
  public static NotificatoinDelete = "Notifications/NotificatoinDelete";
  public static ReadNotifications = "Notifications/ReadNotifications";

  // CongBo
  public static GetCongBoKyHop = "CongBo/GetCongBoKyHop";
  public static GetCongBoKyHopDetail = "CongBo/GetCongBoKyHopDetail";
  public static MeetingGetListNghiQuyetPublish =
    "CongBo/MeetingGetListNghiQuyetPublish";
  public static GetCongBoKienNghi = "CongBo/GetCongBoKienNghi";
  public static GetCongBoKienNghiDetail = "CongBo/GetCongBoKienNghiDetail";
  public static GetCongBoHoSo = "CongBo/GetCongBoHoSo";
  public static GetCongBoHoSoDetail = "CongBo/GetCongBoHoSoDetail";
  public static CongBoLoadFile = "CongBo/LoadFile";
  public static CongBoDownloadFile = "CongBo/DownloadFile";
  public static GopY = "CongBo/GopY";
  public static GetListGopY = "CongBo/GetListGopY";
  public static GetCommentById = "CongBo/GetCommentById";
  public static UserCheckViewDetail = "CongBo/UserCheckViewDetail";

  //Histories
  public static GetHistories = "Histories/GetHistories";

  //Region
  public static GETREGIONFIRSTNODE = "Region/FirstNode";
  public static GETREGIONCHILDNODE = "Region/ChildNode";

  public static REGIONCREATE = "Region/Create";
  public static REGIONUPDATE = "Region/Update";
  public static REGIONDELETE = "Region/Delete";
  public static REGIONGETBYID = "Region/GetbyId";
  public static REGIONGETDropDown = "Region/GetDropDown";
  public static RegionGetDropDownQuanHuyen = "Region/GetDropQuanHuyen";
  public static RegionGetDropDownPhuongXa = "Region/GetDropDownPhuongXa";
  public static RegionGetDropDownQuanHuyen_Group =
    "Region/GetDropDownQuanHuyen_Group";
  public static RegionGetDropDownQuanHuyen_All =
    "Region/GetDropDownQuanHuyenAll";
  public static RegionGetDropDownTinh_Search = "Region/GetDropDownTinhSearch";

  //Business
  public static GetListBusiness = "Business/BusinessGetList";
  public static GetListNhapLieuBusiness = "Business/GetListNhapLieuBusiness";
  public static GetListChoKiemDuyetBusiness =
    "Business/BusinessGetListChoKiemDuyet";
  public static GetListxacnhantrunglapBusiness = "Business/GetListxacnhantrunglapBusiness";
  public static DeleteBusiness = "Business/DeleteBusiness";
  public static DeleteNguoiLD = "Business/DeleteNguoiLD";
  public static UpdateStatusById = "Business/UpdateStatusById";
  public static GetListchokiemduyetLaborer ="Business/GetListchokiemduyetLaborer";
  public static GetListOnlineLaborer ="Business/GetListOnlineLaborer";
  public static GetListxacnhantrunglapLaborer = "Business/GetListxacnhantrunglapLaborer";
  public static GetListhosoLaborer = "Business/GetListhosoLaborer";
  public static UpdateStatusByIdLaborer = "Business/UpdateStatusByIdLaborer";
  public static GetListHistoryByIdBusiness =
    "Business/GetListHistoryByIdBusiness";
  public static GetListHistoryByIdLaborer =
    "Business/GetListHistoryByIdLaborer";
  public static CreateHistory = "HisNguoiSdLaoDong/Create";
  public static CreateHistoryLaborer = "HisNguoiLaoDong/Create";

  //NguoiSdLaoDong
  public static NguoiSdLaoDongCREATE = "NguoiSdLaoDong/Create";
  public static NguoiSdLaoDongUPDATE = "NguoiSdLaoDong/Update";
  public static NguoiSdLaoDongDELETE = "NguoiSdLaoDong/Delete";
  public static NguoiSdLaoDongGETBYID = "NguoiSdLaoDong/GetbyId";
  public static NguoiSdLaoDongGETDropDown = "NguoiSdLaoDong/GetDropDown";
  public static NguoiSdLaoDongLoaiHinhDnCREATE =
    "NguoiSdLaoDong/CreateLoaiHinhDn";
  public static NguoiSdLaoDongNganhKinhDoanhCREATE =
    "NguoiSdLaoDong/CreateNganhKinhDoanh";
  public static NguoiSdLaoDongChucVuNldCREATE =
    "NguoiSdLaoDong/CreateChucVuNld";
  public static BsNguoiSdLaoDongGETBYID = "BsNguoiSdLaoDong/GetById";
  public static NguoiSdLaoDongKinhNghiemLamViecCREATE =
    "NguoiSdLaoDong/CreateKinhNghiemLamViec";
  public static NguoiSdLaoDongChucDanhCREATE = "NguoiSdLaoDong/CreateChucDanh";
  public static NguoiSdLaoDongDoiTuongUuTienCREATE =
    "NguoiSdLaoDong/CreateDoiTuongUuTien";
  public static NguoiSdLaoDongCheDoPhucLoiCREATE =
    "NguoiSdLaoDong/CreateCheDoPhucLoi";
  public static NguoiSdLaoDongKyNangMemCREATE =
    "NguoiSdLaoDong/CreateKyNangMem";
  public static NguoiSdLaoDongGetDropDownLoaiHinhDN =
    "NguoiSdLaoDong/GetDropDownLoaiHinhDN";
  public static NguoiSdLaoDongGetDropDownNganhKinhDoanh =
    "NguoiSdLaoDong/GetDropDownNganhKinhDoanh";
  public static NguoiSdLaoDongGetDropDownChucVu =
    "NguoiSdLaoDong/GetDropDownChucVu";
  public static NguoiSdLaoDongGetDropDownKinhNghiem =
    "NguoiSdLaoDong/GetDropDownKinhNghiem";
  public static NguoiSdLaoDongGetDropDownChucDanhCongViec =
    "NguoiSdLaoDong/GetDropDownChucDanhCongViec";
  public static NguoiSdLaoDongGetDropDownCheDoPhucLoi =
    "NguoiSdLaoDong/GetDropDownCheDoPhucLoi";
  public static NguoiSdLaoDongGetDropDownDoiTuongUuTien =
    "NguoiSdLaoDong/GetDropDownDoiTuongUuTien";
  public static NguoiSdLaoDongGetDropDownKyNangMem =
    "NguoiSdLaoDong/GetDropDownKyNangMem";
  public static NguoiSdLaoDongGetListDuplicate =
    "NguoiSdLaoDong/GetListDuplicate";
  public static NguoiSdLaoDongGetListHistoryByIdBusiness =
    "NguoiSdLaoDong/GetListHistoryByIdBusiness";
  public static BsNguoiSdLaoDongUPDATE = "BsNguoiSdLaoDong/Update";
  public static NguoiLaoDongGetListDuplicate =
    "Business/LbNguoiLaoDongGetListDuplicate";
  public static NguoiLaoDongDuplicateDelete =
    "Business/LbNguoiLaoDongDuplicateDelete";
  public static BsDoTuoiCREATE = "BsNguoiSdLaoDong/CreateDoTuoi";
  public static BsDoTuoiDELETE = "BsNguoiSdLaoDong/DeleteDoTuoi";
  public static BsDoTuoiGetByBusinessId =
    "BsNguoiSdLaoDong/BsDoTuoiGetByBusinessId";
  public static BsTrinhDoNgoaiNguCREATE =
    "BsNguoiSdLaoDong/CreateTrinhDoNgoaiNgu";
  public static BsTrinhDoNgoaiNguDELETE =
    "BsNguoiSdLaoDong/DeleteTrinhDoNgoaiNgu";
  public static BsTrinhDoNgoaiNguGetByBusinessId =
    "BsNguoiSdLaoDong/BsTrinhDoNgoaiNguGetByBusinessId";
  public static BsTrinhDoNgoaiNguUPDATE =
    "BsNguoiSdLaoDong/UpdateTrinhDoNgoaiNgu";
  public static BsTrinhDoTinHocCREATE = "BsNguoiSdLaoDong/CreateTrinhDoTinHoc";
  public static BsTrinhDoTinHocDELETE = "BsNguoiSdLaoDong/DeleteTrinhDoTinHoc";
  public static BsTrinhDoTinHocGetByBusinessId =
    "BsNguoiSdLaoDong/BsTrinhDoTinHocGetByBusinessId";
  public static BsTrinhDoTinHocUPDATE = "BsNguoiSdLaoDong/UpdateTrinhDoTinHoc";
  public static BsKyNangMemKhacCREATE = "BsNguoiSdLaoDong/CreateKyNangMemKhac";
  public static BsKyNangMemKhacDELETE = "BsNguoiSdLaoDong/DeleteKyNangMemKhac";
  public static BsKyNangMemKhacGetByBusinessId ="BsNguoiSdLaoDong/BsKyNangMemKhacGetByBusinessId";
  public static UpdateOnlyNgSdLaoDong = "NguoiSdLaoDong/UpdateOnly"
  public static BsKyNangMemKhacUPDATE = "BsNguoiSdLaoDong/UpdateKyNangMemKhac";
  public static DmKyNangMemGetList = "BsNguoiSdLaoDong/DmKyNangMemGetList";
  public static DmKyNangMemCreate = "BsNguoiSdLaoDong/DmKyNangMemCreate";
  public static BsTrinhDoKhacGetList = "BsNguoiSdLaoDong/TrinhDoKhacGetList";
  public static BsTrinhDoKhacUpdate = "BsNguoiSdLaoDong/TrinhDoKhacUpdate";
  public static BsTrinhDoKhacCreate = "BsNguoiSdLaoDong/TrinhDoKhacCreate";
  public static BsTrinhDoKhacDelete = "BsNguoiSdLaoDong/TrinhDoKhacDelete";
  public static BsCheDoPhucLoiKhacGetList = "BsNguoiSdLaoDong/CheDoPhucLoiKhacGetList";
  public static NguoiDungGetById = "BsNguoiSdLaoDong/NguoiDungGetById";
  public static DashBoardNguoiNhapLieuHoSo = "DashBoard/DashBoardNguoiNhapLieuHoSo";
  public static DashBoardNguoiNhapLieuThongKeNgay = "DashBoard/DashBoardNguoiNhapLieuThongKeNgay";
  public static DashBoardNguoiNhapLieuThongKeKiemDuyet = "DashBoard/DashBoardNguoiNhapLieuThongKeKiemDuyet";
  public static DashBoardNguoiNhapLieuThongKeHoSo = "DashBoard/DashBoardNguoiNhapLieuThongKeHoSo";
  //NguoiLaoDong Detail
  public static KyNangMemKhacGetByNguoiLaoDongId = "NguoiLaoDong/KyNangMemKhacGetByNguoiLaoDongId";
  public static KinhNghiemThucTeGetByNguoiLaoDongId = "NguoiLaoDong/KinhNghiemThucTeGetByNguoiLaoDongId";
  public static CheDoPhuLoiKhacGetByNguoiLaoDongId = "NguoiLaoDong/CheDoPhuLoiKhacGetByNguoiLaoDongId";
  public static DoiTuongUuTienGetByNguoiLaoDongId = "NguoiLaoDong/DoiTuongUuTienGetByNguoiLaoDongId";
  public static DmDoiTuongUuTienGetList = "NguoiLaoDong/DmDoiTuongUuTienGetList";
  public static NguoiLaoDongUpdate = "NguoiLaoDong/Update";
  public static LaborerUpdateOnly= "Laborer/LaborerUpdateOnly";

  // QL ứng viên
  public static GetListUngVien = "UngVien/GetList"
  public static CreateUngVien = "UngVien/Create"
  public static UpdateUngVien = "UngVien/Update"
  public static UpdateStatusUngVien = "UngVien/UpdateStatus"
  public static UngVienGetById = "UngVien/GetById"
  public static UngVienGetNhuCauTimViec = "UngVien/GetNhuCauTimViec"
  public static UngVienDelete = "UngVien/Delete"
  public static UngVienGetListHistory = "HisUngVien/GetListHistory"
  public static CreateHistoryUngVien = "HisUngVien/Create"
  public static UngVienGetListDropDown = "UngVien/GetListDropDown"
  public static UngVienUpdateOnly = "UngVien/UpdateOnly"
  public static GetIdUngVienByCmnd = "UngVien/GetIdUngVienByCmnd"
  // QL ứng viên
  public static GetListNhaTuyenDung = "NhaTuyenDung/GetList"
  public static CreateNhaTuyenDung = "NhaTuyenDung/Create"
  public static NhaTuyenDungGetById = "NhaTuyenDung/GetById"
  public static NhaTuyenDungGetByIdDetail = "NhaTuyenDung/GetByIdDetail"
  public static GetDanhSachDangTuyenDung = "NhaTuyenDung/GetDanhSachDangTuyenDung"
  public static UpdateNhaTuyenDung = "NhaTuyenDung/Update"
  public static NhaTuyenDungDelete = "NhaTuyenDung/Delete"
  public static UpdateStatusNhaTuyenDung = "NhaTuyenDung/UpdateStatus"
  public static NhaTuyenDungGetListHistory = "HisNhaTuyenDung/GetListHistory"
  public static CreateHistoryNhaTuyenDung = "HisNhaTuyenDung/Create"
  public static GetIdNhaTuyenDungByCmnd = "NhaTuyenDung/GetIdNhaTuyenDungByCmnd"
  public static NhaTuyenDungUpdateOnly = "NhaTuyenDung/UpdateOnly"

  //portal
  public static NguoiTimViecGetDataForCreate = "NguoiTimViecPortal/LaborerGetDataForCreate";
  public static NguoiTimViecPortalGetList= "NguoiTimViecPortal/GetList";
  public static CongBoNguoiTimViecGetTatCa = "CongBoNguoiTimViec/TatCa";
  public static CongBoNguoiTimViecGetMoi = "CongBoNguoiTimViec/MoiNhat";
  public static CongBoNguoiTimViecGetNganhNghe= "CongBoNguoiTimViec/NganhNghe";
  public static CongBoNguoiTimViecTongHopNganhNghe= "CongBoNguoiTimViec/TongHopNganhNghe";
  public static LaborerGetByIdPortal = "CongBoNguoiTimViec/LaborerGetByIdPortal";
  public static REGIONGETBYIDPORTAL = "CongBoNguoiTimViec/RegionGetbyId";
  public static LaborerGetDataForCreate2 = "CongBoNguoiTimViec/LaborerGetDataForCreate";
  public static CongBoNguoiTimViecUngVienPhuHop= "CongBoNguoiTimViec/UngVienPhuHop";
  public static CongBoTinTucGetTatCa = "CongBoNguoiTimViec/TatCaTinTuc";
  public static CongBoTinTucGetDropDown= "CongBoNguoiTimViec/GetDropDown";
  public static CongBoTinTucGetListNoiBat= "CongBoNguoiTimViec/GetListNoiBat";
  public static CongBoTinTucGetListRelated= "CongBoNguoiTimViec/GetListRelated";
  public static CongBoNguoiTimViecCreate = "CongBoNguoiTimViec/LaborerCreate";
  public static CongBoGetIdUngVienByCmnd = "CongBoNguoiTimViec/CongBoGetIdUngVienByCmnd";
  public static CongBoGetByIdUngVien = "CongBoNguoiTimViec/CongBoGetByIdUngVien"
  public static CongBoGetListUngVien = "CongBoNguoiTimViec/CongBoGetListUngVien"
  public static CongBoNgTimViecCreatHistory = "CongBoNguoiTimViec/CreateHistory"

  public static DashBoardNguoiKiemDuyetChoKiemDuyetSDLD = "DashBoard/DashBoardNguoiKiemDuyetChoKiemDuyetSDLD";
  public static DashBoardNguoiKiemDuyetChoKiemDuyetLD = "DashBoard/DashBoardNguoiKiemDuyetChoKiemDuyetLD";
  public static DashBoardQuanLyThongKeNgay = "DashBoard/DashBoardQuanLyThongKeNgay";
  public static DashBoardThongKePhongBan = "DashBoard/DashBoardThongKePhongBan";
  public static DashBoardThongKeCaNhan = "DashBoard/DashBoardThongKeCaNhan";
  public static DashBoardDropDownMonth = "DashBoard/DashBoardDropDownMonth";

  public static CongBoNSDLDGetDropDownQuanHuyen = "CongBoNguoiSdLaoDong/GetDropQuanHuyen";
  public static CongBoNSDLDGetDropDownPhuongXa = "CongBoNguoiSdLaoDong/GetDropDownPhuongXa";
  public static CongBoNSDLDGetDropDownTinh = "CongBoNguoiSdLaoDong/GetDropDownTinh";
  public static CongBoNguoiSdLaoDongCREATE = "CongBoNguoiSdLaoDong/Create";
  public static CongBoNguoiSdLaoDongUPDATE = "CongBoNguoiSdLaoDong/Update";
  public static CongBoNguoiSdLaoDongDELETE = "CongBoNguoiSdLaoDong/Delete";
  public static CongBoNguoiSdLaoDongGETBYID = "CongBoNguoiSdLaoDong/GetbyId";
  public static CongBoNguoiSdLaoDongGETDropDown = "CongBoNguoiSdLaoDong/GetDropDown";
  public static CongBoNguoiSdLaoDongDeleteTrinhDoNgoaiNgu = "CongBoNguoiSdLaoDong/TrinhDoNgoaiNgu_Delete";
  public static CongBoNguoiSdLaoDongDeleteTrinhDoTinHocKhac = "CongBoNguoiSdLaoDong/TrinhDoTinHocKhac_Delete";
  public static CongBoNguoiSdLaoDongDeleteTrinhDoKhac = "CongBoNguoiSdLaoDong/TrinhDoKhac_Delete";
  public static NguoiSdLaoDongDeleteTrinhDoNgoaiNgu  = "NguoiSdLaoDong/TrinhDoNgoaiNgu_Delete";
  public static NguoiSdLaoDongDeleteTrinhDoTinHocKhac = "NguoiSdLaoDong/TrinhDoTinHocKhac_Delete";
  public static NguoiSdLaoDongDeleteTrinhDoKhac = "NguoiSdLaoDong/TrinhDoKhac_Delete";
  public static CongBoNguoiSdLaoDongGetTatCa = "CongBoNguoiSdLaoDong/TatCa";
  public static CongBoNguoiSdLaoDongGetMoi = "CongBoNguoiSdLaoDong/MoiNhat";
  public static CongBoNguoiSdLaoDongGetLuongCao = "CongBoNguoiSdLaoDong/LuongCao";
  public static CongBoNguoiSdLaoDongGetNganhNghe= "CongBoNguoiSdLaoDong/NganhNghe";
  public static CongBoNguoiSdLaoDongTongHopNganhNghe= "CongBoNguoiSdLaoDong/TongHopNganhNghe";
  public static CongBoNguoiSdLaoDongUngVienPhuHop= "CongBoNguoiSdLaoDong/UngVienPhuHop";
  public static CongBoNguoiSdLaoDongGetListNhaTuyenDung= "CongBoNguoiSdLaoDong/GetListNhaTuyenDung";
  public static CongBoNguoiSdLaoDongGetByIdNhaTuyenDung= "CongBoNguoiSdLaoDong/GetByIdNhaTuyenDung";
  public static CongBoNguoiSdLaoDongGetIdNhaTuyenDungByCmnd= "CongBoNguoiSdLaoDong/GetIdNhaTuyenDungByCmnd";

  public static TinTucCreate= "TinTuc/Create";
  public static TinTucUpdate= "TinTuc/Update";
  public static TinTucUpdateStatus= "TinTuc/UpdateStatus";
  public static TinTucGetById= "TinTuc/GetById";
  public static TinTucDelete= "TinTuc/Delete";
  public static TinTucGetList= "TinTuc/GetList";
  public static TinTucGetListRelated= "TinTuc/GetListRelated";
  public static TinTucGetDropDown= "TinTuc/GetDropDown";

  public static LaborerUpdateLoaiBaoHiem ="Laborer/LaborerUpdateLoaiBaoHiem";
  public static LaborerUpdateDangKyCungUng ="Laborer/LaborerUpdateDangKyCungUng";
  public static LaborerUpdateDangKyTuVan ="Laborer/LaborerUpdateDangKyTuVan";
  public static LaborerUpdateGioiThieuCungUng ="Laborer/LaborerUpdateGioiThieuCungUng";
  public static LaborerUpdateHinhThucTuyenDung ="Laborer/LaborerUpdateHinhThucTuyenDung";
  public static LaborerUpdateKenhTiepNhanTT ="Laborer/LaborerUpdateKenhTiepNhanTT";
  public static LaborerUpdateDoiTuongUuTien ="Laborer/LaborerUpdateDoiTuongUuTien";
  public static LaborerUpdateKyNangMem ="Laborer/LaborerUpdateKyNangMem";

  public static UngVienUpdateLoaiBaoHiem ="UngVien/UngVienUpdateLoaiBaoHiem";
  public static UngVienUpdateDangKyCungUng ="UngVien/UngVienUpdateDangKyCungUng";
  public static UngVienUpdateDangKyTuVan ="UngVien/UngVienUpdateDangKyTuVan";
  public static UngVienUpdateGioiThieuCungUng ="UngVien/UngVienUpdateGioiThieuCungUng";
  public static UngVienUpdateHinhThucTuyenDung ="UngVien/UngVienUpdateHinhThucTuyenDung";
  public static UngVienUpdateKenhTiepNhanTT ="UngVien/UngVienUpdateKenhTiepNhanTT";
  public static UngVienUpdateDoiTuongUuTien ="UngVien/UngVienUpdateDoiTuongUuTien";
  public static UngVienUpdateKyNangMem ="UngVien/UngVienUpdateKyNangMem";

  public static BsDangKyCungUngUpdate="NguoiSdLaoDong/BsDangKyCungUngUpdate";
  public static BsDangKyTuVanUpdate="NguoiSdLaoDong/BsDangKyTuVanUpdate";
  public static BsGioiThieuCungUngUpdate="NguoiSdLaoDong/BsGioiThieuCungUngUpdate";
  public static BsHinhThucTuyenDungUpdate="NguoiSdLaoDong/BsHinhThucTuyenDungUpdate";
  public static BsKenhTiepNhanThongTinUpdate="NguoiSdLaoDong/BsKenhTiepNhanThongTinUpdate";

  public static QlNtdDangKyCungUngUpdate="NhaTuyenDung/QlNtdDangKyCungUngUpdate";
  public static QlNtdDangKyTuVanUpdate="NhaTuyenDung/QlNtdDangKyTuVanUpdate";
  public static QlNtdGioiThieuCungUngUpdate="NhaTuyenDung/QlNtdGioiThieuCungUngUpdate";
  public static QlNtdHinhThucTuyenDungUpdate="NhaTuyenDung/QlNtdHinhThucTuyenDungUpdate";
  public static QlNtdKenhTiepNhanThongTinUpdate="NhaTuyenDung/QlNtdKenhTiepNhanThongTinUpdate";

  public static CongBoNguoiSDLDCreateHistory = "CongBoNguoiSdLaoDong/CreateHistory";
  public static NguoiSdLaoDongGetListOnline="NguoiSdLaoDong/GetListOnline";
  public static NguoiSdLaoDongTraLai="NguoiSdLaoDong/TraLai";
  public static NguoiSdLaoDongGetListNguoiKiemDuyetOnline="NguoiSdLaoDong/GetListNguoiKiemDuyetOnline";
  public static LaborerGetListNguoiKiemDuyetOnline="Laborer/GetListNguoiKiemDuyetOnline";

  public static DashBoardNguoiKiemDuyetThongKeKiemDuyet = "DashBoard/DashBoardNguoiKiemDuyetThongKeKiemDuyet";
  public static DashBoardNguoiKiemDuyetThongKeHoSo = "DashBoard/DashBoardNguoiKiemDuyetThongKeHoSo";
  public static DashBoardNguoiQuanLyThongKeHoSo = "DashBoard/DashBoardNguoiQuanLyThongKeHoSo";

  public static KhoiGetList = "Catalog/KhoiGetList";
  public static PhongGetList = "Catalog/PhongGetList";
  public static MucDoQuanTamGetList = "Catalog/MucDoQuanTamGetList";
  public static ThanhKhoanGetList = "Catalog/ThanhKhoanGetList";
  public static MucDichMuaNhaGetList = "Catalog/MucDichMuaNhaGetList";
  public static NgoTemplateGetList = "Catalog/NgoTemplateGetList";

  public static LoaiHinhGetList = "Catalog/LoaiHinhGetList";
// dm Tuyen Phố
public static TuyenPhoCreate = "TuyenPho/Create";
public static TuyenPhoGetList = "TuyenPho/GetList";
public static TuyenPhoUpdate = "TuyenPho/Update";
public static TuyenPhoGetById = "TuyenPho/GetById";
public static DeleteTuyenPho = "TuyenPho/Delete";
public static TuyenPhoGetByPhuongXa = "TuyenPho/GetByPhuongXa";
// danh sách nhà
public static DropDownDauChu = "DanhSachNha/DropDownDauChu";
public static DanhSachNhaCreate = "DanhSachNha/Create";
public static DanhSachNhaImport = "DanhSachNha/Import";
public static DanhSachNhaImportPhanKhuc = "DanhSachNha/ImportPhanKhuc";
public static GetListDanhSachNha = "DanhSachNha/GetList";
public static ExportDanhSachNha = "DanhSachNha/Export";
public static UpdateNhanhDanhSachNha = "DanhSachNha/UpdateNhanh";
public static GetListKhaoSatDanhSachNha = "DanhSachNha/GetListKhaoSat";
public static GetListDanhSachNhaQuangCao = "DanhSachNha/GetListQuangCao";
public static RegionGetDropDownPhoByQuan = "Region/GetDropDownPhoByQuan";
public static RegionGetDropDownPhoByPhuong = "Region/GetDropDownPhoByPhuong";
public static DanhSachNhaGetById = "DanhSachNha/GetById";
public static DanhSachNhaGetByIdKhaoSat= "DanhSachNha/GetByIdKhaoSat";
public static DanhSachNhaRemoveImgDauChuOld = "DanhSachNha/RemoveImgDauChuOld";
public static DanhSachNhaRemoveImgMoTaOld = "DanhSachNha/RemoveImgMoTaOld";
public static DanhSachNhaUpdate = "DanhSachNha/Update";
public static DeleteDanhSachNha = "DanhSachNha/Delete";
public static UpdateAnDanhSachNha = "DanhSachNha/UpdateAn";
public static UpdateDongBoDanhSachNha = "DanhSachNha/UpdateDongBo";
public static DanhSachNhaDongBo = "DanhSachNha/DongBo";
public static GetDropDataForDanhSachNha = "DanhSachNha/GetDropData";
public static GetDropDataDuAnForDanhSachNha = "DanhSachNha/GetDropDataDuAn";
public static UpdateCallioDanhSachNha = "DanhSachNha/UpdateCallio";
public static GetCallioDanhSachNha = "DanhSachNha/GetInfoCallio";
public static UpdateRecodingDanhSachNha = "DanhSachNha/UpdateRecoding";
public static GetListRecordingDanhSachNha = "DanhSachNha/GetListRecord";
public static GetFileRecordingDanhSachNha = "DanhSachNha/GetFileRecord";
public static GetListDongBoSdtDanhSachNha = "DanhSachNha/GetListDongBoSdt";


// banner
public static BannerCreate = "Banner/Create";
public static GetListBanner = "Banner/GetList";
public static BannerUpdate = "Banner/Update";
public static DeleteBanner = "Banner/Delete";
public static BannerGetById = "Banner/GetById";



// Trang chủ
public static DropDownBanner = "TrangChu/DropDownBanner";
public static DropDownTinHot = "TrangChu/DropDownTinHot";
public static DropDownTinHotQuangCao = "TrangChu/DropDownTinHotQuangCao";
public static ListSearch = "TrangChu/ListSearch";
public static ListSearchCaNhan = "TrangChu/ListSearchCaNhan";
public static GetlistPhuongSearch = "TrangChu/GetlistPhuongSearch";
public static GetlistPhuongDetail = "TrangChu/GetlistPhuongDetail";
public static DanhSachNhaLike = "TrangChu/DanhSachNhaLike";
public static GetListSearch = "TrangChu/GetListSearch";
public static GetListSearchLoc = "TrangChu/GetListSearchLoc";
public static GetListLuuTru = "TrangChu/GetListLuuTru";
public static GetListThuMuc = "TrangChu/GetListThuMuc";
public static GetDetail = "TrangChu/GetDetail";
public static GetRecomment = "TrangChu/GetRecomment";
public static getSuggest = "TrangChu/getSuggest";
public static DanhSachNhaLuuTru = "TrangChu/DanhSachNhaLuuTru";
public static DanhSachNhaUnLike = "TrangChu/DanhSachNhaUnLike";
public static DanhSachNhaListCommentById = "TrangChu/DanhSachNhaListCommentById";
public static DanhSachNhaListThaoLuanById = "TrangChu/DanhSachNhaListThaoLuanById";
public static GetDanhSachNgoCommentById = "TrangChu/GetDanhSachNgoCommentById";
public static DanhSachNhaCreateComment = "TrangChu/DanhSachNhaCreateComment";
public static DanhSachNhaCreateThaoLuan = "TrangChu/DanhSachNhaCreateThaoLuan";
public static DanhSachNhaCreateCommentNgo = "TrangChu/DanhSachNhaCreateCommentNgo";
public static DanhSachNhaCreateThuMuc = "TrangChu/DanhSachNhaCreateThuMuc";
public static GetListKhaoSatNgo = "TrangChu/GetListKhaoSatNgo";
public static GetListSaved = "TrangChu/GetListSaved";
public static DanhSachNhaLogShare = "TrangChu/DanhSachNhaLogShare";

// HtOTP
public static GetListHtEmail = "HtEmail/GetListHistory"
public static CreateHtEmail= "HtEmail/Create"
public static DeleteOTP = "HtEmail/Delete";

 //NCC _ Người Dùng
 public static GET_NCCNGUOIDUNG_BY_DEPARTMENT = "NccNguoiDung/GetUserByDepartment";
 public static CREATE_NCCNGUOIDUNG = "NccNguoiDung/CreateUser";
 public static GET_CREATE_NCCNGUOIDUNG_DATAS = "NccNguoiDung/GetCreateUserDatas";
 public static GETNCCNGUOIDUNGBYGROUPID = "NccNguoiDung/GetListUserByGroupId";
 public static Edit_NCCNGUOIDUNG = "NccNguoiDung/EditNccNguoiDung";
 public static UPDATE_INFO_NCCNGUOIDUNG = "NccNguoiDung/UpdateInfo";
 public static UPDATE_NCCNGUOIDUNG_LOGIN = "NccNguoiDung/UpdateUserLogin";
 public static LOCK_NCCNGUOIDUNG = "NccNguoiDung/LockUser";
 public static DELETE_NCCNGUOIDUNG = "NccNguoiDung/DeleteUser";
 public static logOut_NCCNGUOIDUNG = "NccNguoiDung/LogOut";
 public static GET_NCCNGUOIDUNG_BY_REGION = "NccNguoiDung/GetUserByRegion";
 public static GET_NCCNGUOIDUNG_BY_UNIT = "NccNguoiDung/GetUserByUnit";
 public static GetAllNCCNGUOIDUNGByUnitForForward = "NccNguoiDung/GetAllUserByUnitForForward";
 public static GET_NCCNGUOIDUNG_BY_UNIT_AND_QUERY = "NccNguoiDung/GetUserByUnitAndQuery";
 public static GetNCCNGUOIDUNGById = "NccNguoiDung/GetNccNguoiDungById";
 public static IsNguoiDaiDien_NCCNGUOIDUNG = "NccNguoiDung/IsNguoiDaiDien";
 public static NCCNGUOIDUNGUpdateStatus = "NccNguoiDung/UpdateStatus";
 public static NCCNGUOIDUNGUpdateNangCao = "NccNguoiDung/UpdateNangCao";
 public static GET_NCCNGUOIDUNG_BY_REGION_UNIT = "NccNguoiDung/GetUserByRegionOrUnit";
 public static GET_NCCNGUOIDUNG_LIST = "NccNguoiDung/GetNccNguoiDung";
 public static GET_NCCNGUOIDUNG_DROPDOWN = "NccNguoiDung/GetDropDown";
 public static GET_TrangChu_DROPDOWN = "TrangChu/GetDropDown";
 public static GET_TrangChu_DROPDOWNThuMuc = "TrangChu/GetDropDownThuMuc";

 public static GetNCCNGUOIDUNGThongTinById = "NccNguoiDung/GetNccNguoiDungThongTinById";
 public static EditNccNguoiDungThongTin = "NccNguoiDung/EditNccNguoiDungThongTin";
 public static CreateNccNguoiDungThongTin = "NccNguoiDung/CreateNccNguoiDungThongTin";
 public static DeleteNccNguoiDungThongTin = "NccNguoiDung/DeleteNccNguoiDungThongTin";
 public static GetNccNguoiDungThongTinByNguoiDung = "NccNguoiDung/GetNccNguoiDungThongTinByNguoiDung";
 public static GetNccNguoiDungThongTinByChuyenVien = "NccNguoiDung/GetNccNguoiDungThongTinByChuyenVien";

 public static GetNccNguoiDungQuanHeById = "NccNguoiDung/GetNccNguoiDungQuanHeById";
 public static EditNccNguoiDungQuanHe = "NccNguoiDung/EditNccNguoiDungQuanHe";
 public static CreateNguoiDungQuanHe = "NccNguoiDung/CreateNguoiDungQuanHe";
 public static DeleteNccNguoiDungQuanHe = "NccNguoiDung/DeleteNccNguoiDungQuanHe";
 public static GetNccNguoiDungQuanHeByNguoiDung = "NccNguoiDung/GetNccNguoiDungQuanHeByNguoiDung";
 public static DeleteNccNguoiDungFile = "NccNguoiDung/DeleteNccNguoiDungFile";

  //LichSuDangNhap
  public static GetLichSuDangNhapDataGrid = "SystemLog/GetLichSuDangNhapDataGrid";
  public static LichSuDangNhapDelete = "SystemLog/LichSuDangNhapDelete";
  public static SystemLogDangXuat = "SystemLog/DangXuat";
  public static GetTimeLineLicSuDangNhap = "SystemLog/GetTimeLineLichSuDangNhap";
  public static TimeLineLichSuDangNhapSearch = "SystemLog/TimeLineLichSuDangNhapSearch";
  public static GetTimeLineByUser = "SystemLog/GetTimeLineByUser";
  public static SystemLogCreate = "SystemLog/SystemLogCreate";
  public static GetAllSystemLog = "SystemLog/GetAllSystemLog";
  public static GetAllSystemLogNgoai = "SystemLog/GetAllSystemLog1";
  public static GetListNccNguoiDungLichSuXemNha = "NccNguoiDung/GetListNccNguoiDungLichSuXemNha";
  public static GetListNccNguoiDungLichSuXemNhaByChuyenVien = "NccNguoiDung/GetListNccNguoiDungLichSuXemNhaByChuyenVien";
  public static GetListNccNguoiDungNhaByDauChu = "NccNguoiDung/GetListNccNguoiDungNhaByDauChu";
  
  public static CheckOtp = "TrangChu/CheckOtp";
  public static CheckCanhBao = "TrangChu/CheckCanhBao";
  public static DeleteCanhBao = "TrangChu/DeleteCanhBao";
  public static DeleteCanhBaoLoai = "TrangChu/DeleteCanhBaoLoai";
  public static DeleteOtp = "TrangChu/OtpDelete";
  public static RegisterUser = "TrangChu/RegisterUser";
  public static GeMoretNCCNGUOIDUNGThongTinById = "NccNguoiDung/GetNccNguoiDungThongTinMoreById";
  public static GetNCCNGUOIDUNGByPhone = "NccNguoiDung/GetNccNguoiDungByPhone";
  public static TrangChu_GetNCCNGUOIDUNGByPhone = "TrangChu/GetNccNguoiDungByPhone";
  public static TrangChu_SearhNhaByThongSo = "TrangChu/SearhNhaByThongSo";
  public static EditNccNguoiDungLichSuXemNha = "NccNguoiDung/EditNccNguoiDungLichSuXemNha";
 public static CreateNguoiDungLichSuXemNha = "NccNguoiDung/CreateNguoiDungLichSuXemNha";
 public static DeleteNccNguoiDungLichSuXemNha = "NccNguoiDung/DeleteNccNguoiDungLichSuXemNha";
 public static DeleteLuuTru = "TrangChu/LuuTruDelete";
 public static DeleteThuMuc = "TrangChu/DeleteThuMuc";
 public static LuuTruDeleteAll = "TrangChu/LuuTruDeleteAll";
 public static GetlistDuongPho = "TrangChu/GetlistDuongPho";
 public static DropDownTrangChu = "TrangChu/DropDownTrangChu";
 public static GetDropDownTrangChuQuangCao = "TrangChu/DropDownTrangChuQuangCao";
 public static TrangChuTuyenPhoGetById = "TrangChu/GetTuyenPhoById";
 public static GetlistDuongPhoPhanKhuc = "TrangChu/GetlistDuongPhoPhanKhuc";
 public static NccNguoiDungUpdateType = "NccNguoiDung/UpdateType";
 public static SendOTP = "TrangChu/SendOTP";
 // dm Ngo
public static NgoCreate = "Ngo/Create";
public static NgoGetList = "Ngo/GetList";
public static NgoUpdate = "Ngo/Update";
public static NgoGetById = "Ngo/GetById";
public static DeleteNgo = "Ngo/Delete";
//
public static SystemLogDeleteCommnent = "SystemLog/DeleteComment";
public static SystemLogDeleteDanhGia = "SystemLog/DeleteDanhGia";
public static SystemLogDeleteKhaoSatNgo = "SystemLog/DeleteKhaoSatNgo";

public static DropDownNgoTemplate = "TrangChu/GetDropDownNgoTemplate";
public static TrangChuGetListNew = "TrangChu/GetListNew";
public static TrangChuGetCountNew = "TrangChu/GetCountNew";

public static TrangChuGetListNccNguoiDungByLoai = "TrangChu/GetListNccNguoiDungByLoai";
public static TrangChuLockUser = "TrangChu/LockUser";
public static TrangChuLogXemThongSo = "TrangChu/LogXemThongSo";

 // dm Ngo
 public static ThongBaoCreate = "ThongBao/Create";
 public static ThongBaoGetList = "ThongBao/GetList";
 public static ThongBaoGetListUser = "ThongBao/GetListUser";
 public static ThongBaoPushListUser = "ThongBao/PushListUser";
 public static ThongBaoCutListUser = "ThongBao/CutListUser";
 public static ThongBaoUpdate = "ThongBao/Update";
 public static ThongBaoGetById = "ThongBao/GetById";
 public static DeleteThongBao = "ThongBao/Delete";
 public static ThongBaoGetByIdListByThongBao = "ThongBao/GetByIdThongBao";

 public static TrangChuGetNccNguoiDungThongTinByChuyenVienDropDown = "NccNguoiDung/GetNccNguoiDungThongTinByChuyenVienDropDown";
 public static TrangChuGetAllSystemLogByChuyenVien = "NccNguoiDung/GetAllSystemLogByChuyenVien";

  // dm Nguon
public static DmNguonCreate = "DmNguon/Create";
public static DmNguonGetList = "DmNguon/GetList";
public static DmNguonUpdate = "DmNguon/Update";
public static DmNguonGetById = "DmNguon/GetById";
public static DmNguonDelete = "DmNguon/Delete";

  // dm môi giới
  public static DmMoiGioiCreate = "DmMoiGioi/Create";
  public static DmMoiGioiGetList = "DmMoiGioi/GetList";
  public static DmMoiGioiUpdate = "DmMoiGioi/Update";
  public static DmMoiGioiGetById = "DmMoiGioi/GetById";
  public static DmMoiGioiDelete = "DmMoiGioi/Delete";
  public static DmMoiGioiUpdateMoiGioi = "DmMoiGioi/UpdateMoiGioi";
  public static DmMoiGioiUpdateChinhChu = "DmMoiGioi/UpdateChinhChu";
  public static DmMoiGioiImport = "DmMoiGioi/Import";

 // dm Loại hình
 public static DmLoaiHinhCreate = "DmLoaiHinh/Create";
 public static DmLoaiHinhGetList = "DmLoaiHinh/GetList";
 public static DmLoaiHinhGetListThongBao = "DmLoaiHinh/GetListThongBao";
 public static DmLoaiHinhUpdateStatusThongBao = "DmLoaiHinh/UpdateStatusThongBao";
 public static DmLoaiHinhCountThongBao = "DmLoaiHinh/CountThongBao";
 public static DmLoaiHinhUpdate = "DmLoaiHinh/Update";
 public static DmLoaiHinhGetById = "DmLoaiHinh/GetById";
 public static DmLoaiHinhDelete = "DmLoaiHinh/Delete";
  // dm dự án
  public static DmDuAnCreate = "DmDuAn/Create";
  public static DmDuAnGetList = "DmDuAn/GetList";
  public static DmDuAnGetListLoaiHinh = "DmDuAn/GetListLoaiHinh";
  public static DmDuAnUpdate = "DmDuAn/Update";
  public static DmDuAnGetById = "DmDuAn/GetById";
  public static DmDuAnDelete = "DmDuAn/Delete";

  // dm ngân hàng
  public static DmNganHangCreate = "DmNganHang/Create";
  public static DmNganHangGetList = "DmNganHang/GetList";
  public static DmNganHangUpdate = "DmNganHang/Update";
  public static DmNganHangGetById = "DmNganHang/GetById";
  public static DmNganHangDelete = "DmNganHang/Delete";

 // dm gia hạn
 public static NccGiaHanCreate = "NccGiaHan/Create";
 public static NccGiaHanGetList = "NccGiaHan/GetList";
 public static NccGiaHanGetDmGoi = "NccGiaHan/GetDmGoi";
 public static NccGiaHanUpdate = "NccGiaHan/Update";
 public static NccGiaHanGetById = "NccGiaHan/GetById";
 public static NccGiaHanDelete = "NccGiaHan/Delete";

//dm gói dịch vụ
public static DmGoiDichVuCreate = "DmGoiDichVu/Create";
public static DmGoiDichVuGetList = "DmGoiDichVu/GetList";
public static DmGoiDichVuUpdate = "DmGoiDichVu/Update";
public static DmGoiDichVuGetById = "DmGoiDichVu/GetById";
public static DmGoiDichVuDelete = "DmGoiDichVu/Delete";

 // dm Bảng giá
 public static DmBangGiaCreate = "DmBangGia/Create";
 public static DmBangGiaGetList = "DmBangGia/GetList";
 public static DmBangGiaUpdate = "DmBangGia/Update";
 public static DmBangGiaGetById = "DmBangGia/GetById";
 public static DmBangGiaDelete = "DmBangGia/Delete";



}
